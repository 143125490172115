<template>
  <b-navbar class="navbar navbar-light white navbar-expand-lg scrolling-navbar"
            :fixed="navFixed === 'true' ? 'top': ''"
            toggleable>
    <div class="container-fluid">
      <!-- Logo -->
      <b-navbar-brand href="/">
        <img class="navbar-logo" src="../../assets/images/h3_logo.png"/>
      </b-navbar-brand>

      <!-- Toogle -->
      <b-navbar-toggle target="navbar-nav"></b-navbar-toggle>
      <!-- Download Nav Links -->
      <b-collapse is-nav id="navbar-nav">
        <b-navbar-nav>
          <b-nav-item class="store-nav">
            <img class="app-store"
                 :src="appStoreImg"
                 alt="app-store"
                 @click="redirect(appStoreLink)" />
            <img class="play-store"
                 :src="playStoreImg"
                 alt="play-store"
                  @click="redirect(playStoreLink)" />
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right Nav Links -->
        <b-navbar-nav class="ml-auto"
                      v-if="this.$store.getters['auth/isLoggedIn'] &&
                        ($route.path == '/pricing' ||
                        $route.path == '/pricing/2')">
          <b-nav-item :class="{active: $route.path == '/pricing'}"
                      href="/pricing">
            Pricing
          </b-nav-item>
          <b-nav-item :class="{active: $route.path == '/login'}"
                      href="/"
                      @click.native="logoutUser">
            Logout
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto" v-else>
          <b-nav-item :class="{active: $route.path == '/'}"
                      href="/">
            Home
          </b-nav-item>
          <b-nav-item :class="{active: $route.path == 'school'}"
                      href="/school">
            Schools
          </b-nav-item>
          <b-nav-item :class="{active: $route.path == '/parents'}"
                       href="/parents" >
            Parents
          </b-nav-item>
          <b-nav-item :class="{active: $route.path == '/our-mission'}"
                      href="/our-mission">
            Our Mission
          </b-nav-item>
          <b-nav-item :class="{active: $route.path == '/pricing'}"
                      href="/pricing">
            Pricing
          </b-nav-item>
          <b-nav-item :class="{active: $route.path == '/login'}"
                      href="/login">
            Login
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </div>
    <!-- </div> -->
  </b-navbar>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name  : 'NavBar',
    props : {
      fixedNav : String,
    },
    data() {
      return {
        navFixed    : this.fixedNav,
        appStoreImg : 'https://h3-bucket-s3.s3-us-west-2.amazonaws.com/' +
          'appstore_download.svg',
        playStoreImg : 'https://h3-bucket-s3.s3-us-west-2.amazonaws.com/' +
          'playstore_download.svg',
        appStoreLink : 'https://apps.apple.com/us/app/healthy-hip-hop/' +
          'id1456576616',
        playStoreLink : 'https://play.google.com/store/apps/details?' +
          'id=com.healthyhiphop',
      };
    },
    methods : {
      ...mapActions({
        'logout' : 'auth/logout',
      }),

      /**
       * Redirect page to download H3 App
       * @param link
       */
      redirect(link) {
        if(link == this.appStoreLink) {
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'landing-page-navbar',
            'platform' : 'website',
            'to'       : 'appstore-ios',
          });
        } else {
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'landing-page-navbar',
            'platform' : 'website',
            'to'       : 'playstore-android',
          });
        }
        window.open(link, '_target');
      },

      /**
       * Logout User Account
       */
      logoutUser() {
        this.logout();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/shared/navbar";
</style>
